import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import PageHeader from '../../../PageHeader';
import data from '../../../../data/siteDirectory';

import styles from './relationshipsDirectory.module.scss';

const RelationshipsDirectory = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108735/therapistFullBackgroung_lapqmb.jpg';

  const relationshipSpecialties = _.map(data.relationships, (specialty, index) => {
    return (
      <li key={index}>
        <span>
          <Link to={specialty.route}>{specialty.name}</Link>
        </span>
      </li>
    );
  });

  return (
    <main>
      <PageHeader pageName="Relationships" headerImage={background} />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Relationship Specialties</h2>

        <ul>{relationshipSpecialties}</ul>
      </div>
    </main>
  );
};

export default RelationshipsDirectory;
