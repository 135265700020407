import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import RelationshipsDirectory from '../../components/Relationships/components/RelationshipsDirectory';

export default () => (
  <>
    <SEO
      title="Relationship Specialties | Mindset Family Therapy"
      description="Mindset Family Therapy relationship specialties."
      pathname="/relationships"
    />
    <Layout>
      <RelationshipsDirectory />
    </Layout>
  </>
);
